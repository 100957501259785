import moment from "moment";

const initialStartDate = moment().startOf("month").utc().format();
const initialEndDate = moment().endOf("month").utc().format();

const filterState = {
  marketplace: [
    { name: "Amazon-India", id: "amazon-india", checked: true },
    { name: "Flipkart", id: "flipkart", checked: true },
    { name: "Meesho", id: "meesho", checked: true },
    { name: "Myntra", id: "myntra", checked: true },
    { name: "Shopify", id: "shopify", checked: true },

// latest added new channels in initial state
    { name: "Ajio", id: "Ajio", checked: true },
    { name: "AmazonVCDF", id: "AmazonVCDF", checked: true },
    { name: "AmazonVCP", id: "AmazonVCP", checked: true },
    { name: "B2B Offline Sales", id: "B2B Offline Sales", checked: true },
    { name: "BigBasket", id: "BigBasket", checked: true },
    { name: "Blinkit", id: "Blinkit", checked: true },
    { name: "Cred", id: "Cred", checked: true },
    { name: "Cult fit", id: "Cult fit", checked: true },
    { name: "Firstcry", id: "Firstcry", checked: true },
    { name: "FlipkartVendor", id: "FlipkartVendor", checked: true },
    { name: "Ecommerce", id: "Ecommerce", checked: true },
    { name: "Glowroad", id: "Glowroad", checked: true },
    { name: "Jiomart", id: "Jiomart", checked: true },
    { name: "Limeroad", id: "Limeroad", checked: true },
    { name: "Nykaa", id: "Nykaa", checked: true },
    { name: "Nykaafashion", id: "Nykaafashion", checked: true },
    { name: "OMS-Guru", id: "OMS-Guru", checked: true },
    { name: "Pepperfry", id: "Pepperfry", checked: true },
    { name: "Snapdeal", id: "Snapdeal", checked: true },
    { name: "Swiggy", id: "Swiggy", checked: true },
    { name: "Tatacliq", id: "Tatacliq", checked: true },
    { name: "TechnoSport", id: "TechnoSport", checked: true },
    { name: "Wareiq", id: "Wareiq", checked: true },
    { name: "Zepto", id: "Zepto", checked: true },

  ],
  inventorymarketplace: [
    { name: "Amazon-India", label: "Amazon FBA",id: "amazon-india", checked: true },
    { name: "Flipkart",label: "Flipkart FBF", id: "flipkart", checked: true },
    { name: "Myntra",label: "Myntra FBM" ,id: "myntra", checked: true },
    { name: "OFFLINE",label: "OFFLINE" ,id: "offline", checked: true },
    { name: "Merchant",label: "Merchant", id: "merchant", checked: true },
  ],
  dateFilter: null,
  startDate: initialStartDate,
  endDate: initialEndDate,
  reviewStatus: 0,
  sliderCollapsed: false
};

export default function filterReducer(state = filterState, action) {
  switch (action.type) {
    case "filter/updateMarketplace": {
      return {
        ...state,
        marketplace: action.payload.marketplace,
      };
    }
    case "filter/updateInventoryMarketplace": {
      return {
        ...state,
        inventorymarketplace: action.payload.inventorymarketplace,
      };
    }
    case "filter/updateDateFilter": {
      return {
        ...state,
        dateFilter: action.payload.dateFilter,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    }
    case "filter/updateReviewFilter": {
      return {
        ...state,
        reviewStatus: action.payload.reviewStatus,
      };
    }
    case "filter/updateSliderFilter": {
			return {
				...state,
				sliderCollapsed: action.payload.sliderCollapsed,
			};
		}

    default: {
      return state;
    }
  }
}
