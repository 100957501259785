export const ApiTable = {
  loginSelf: {
    callType: "POST",
    isAuthenticated: false,
    baseUrl: process.env.REACT_APP_API_LOGIN_URL,
    endpoint: "/login/self",
  },
  refreshTokenSelf: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LOGIN_URL,
    endpoint: "/refresh-token/self",
  },
  sendOtpSelf: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/send-otp/self",
  },
  forgotPasswordSelf: {
    callType: "POST",
    isAuthenticated: false,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/forgot-password/self",
  },
  verifyOtpSelf: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/verify-otp/self",
  },
  registerSelf: {
    callType: "POST",
    isAuthenticated: false,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/signup/self",
  },
  createUser: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/signup/admin",
  },
  RemoveMember: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/remove-member/admin",
  },
  verifyOtpAdmin: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/verify-otp/admin",
  },
  requestChannel: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_CHANNEL_URL,
    endpoint: "/add-channel",
  },

  changePassword: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/change-password/self",
  },
  editUserInfo: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/edit-user-info/self",
  },
  editUserAdmin: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/edit-user-info/admin",
  },
  editOrganisation: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/edit-organisation-info/admin",
  },
  changeRole: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/change-role/admin",
  },

  updateInventory_v2: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/update-listings-v2",
  },
  getAmazonCategories: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/get-amazon-categories",
  },
  updateAmazonCategory: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/update-amazon-category",
  },
  updateAmazonCategoryReconciliation: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/update-amazon-category-reconciliation",
  },

  MarkProductReturn: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/mark-returns",
  },

  manualSync: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_CHANNEL_URL,
    endpoint: "/manual-sync",
  },
  manualPendingSync: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_CHANNEL_URL,
    endpoint: "/get-manual-sync-pending-requests",
  },
  getCustomerDetails: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_ADMIN_URL,
    endpoint: "/get-organisations",
  },
  getSyncDetails: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_ADMIN_URL,
    endpoint: "/get-organisations-channels",
  },
  enterOrganisation: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_ADMIN_URL,
    endpoint: "/enter-organisation",
  },
  updateOrganisation: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    endpoint: "/config_update",
  },

  ToggleOutwardSync: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_ADMIN_URL,
    endpoint: "/toggle-outward-inventory-sync",
  },
  UpdateAdminDetails_v2: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_ADMIN_URL,
    endpoint: "/admin-update-organisation-details-v2",
  },
  AdminManualSync: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_ADMIN_URL,
    endpoint: "/admin-manual-sync",
  },

  getResponse: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/get-response",
  },

 
  setFcPincode: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/set-fc-pincode",
  },
  updateAmazonChannelAccess: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_CHANNEL_URL,
    endpoint: "/update-amazon-channel-access",
  },
  updateFlipkartChannelAccess: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_CHANNEL_URL,
    endpoint: "/update-flipkart-channel-access",
  },
  updateChannelAccess: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_CHANNEL_URL,
    endpoint: "/update-channel",
  },
  updateChannelSettings: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_CHANNEL_URL,
    endpoint: "/update-channel-details-org",
  },

  customerSyncStatus: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_ADMIN_URL,
    endpoint: "/toggle-organisation-sync-status",
  },

  subscriptionEndDate: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_ADMIN_URL,
    endpoint: "/admin-update-organisation-details",
  },
  getCouponData: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    endpoint: "/coupon_enable",
  },

  allReviewsV4: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/reviews_and_ratings",
  },
  review_summary: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/review_summary",
  },
  sentiment: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/sentiment",
  },
  reviewclassifier: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/reviewclassifier",
  },
  getChannelURL: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_CHANNEL_URL,
    endpoint: "/get-channels",
  },
  listUserAdmin: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/list-users/admin",
  },
  getUserRoles: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/get-user-roles",
  },

  profitSummaryCard: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/profitability_cards",
  },
  profitSummaryTable: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/profitability_table",
  },

  getOrganisationReportApi: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/create-organisation-report-request",
  },
  getExportData: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/check-organisation-report-request",
  },
  pincodeApi: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/view-fulfillment-centers",
  },
  updatePincodeApi: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/update-fulfillment-centers-data",
  },
  getExpiredFileData: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/download-organisation-report-request",
  },
  //------------------///

  updateChangeLog: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/change_log",
  },
  updatePermission: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/update-permissions",
  },
  queryUserActivity: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/query-user-activity",
  },
  OrgDetails: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/edit-organisation-info-v2",
  },

  sentimentAnalysis: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/sentiment-analysis",
  },

  getKeyWordsTrackers: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/get_keyword_tracker_postgres",
  },
  addKeyWordsTrackers: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/add_keywords_postgres",
  },
  deleteKeyWordsTrackers: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/delete_keywords_postgres",
  },
  CreateNewUrlApi: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    endpoint: "/url_rotators",
  },
  amazonIxdApi: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    endpoint: "/amazon_ixd",
  },
  keywordbulkUpload: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/add_keywords_bulk_postgres",
  },
  influencerCode: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LOGIN_URL,
    endpoint: "/influencer_code",
  },
  reverseLookup: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/get_reverse_lookup_postgres",
  },
  addReverseLookup: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/reverse_lookup_postgres",
  },

  returnv2: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    endpoint: "/returnv2",
  },


  getSummaryInventory: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/inventory_insights",
  },

  sellerFlexUpload: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_SELLERFLEX_URL,
    endpoint: "/upload-file",
  },
  keywordsInsight: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/keyword_insights",
  },

  modularityApi: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/modularity-list",
  },
  user_coupon_details: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/user-coupon-details",
  },
  priceTracking: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    endpoint: "/price_trackingv3",
  },
  priceRecommandation: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    endpoint: "/recommendationinventory",
  },
  pricingCampaignList: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    endpoint: "/campaignlistv2",
  },
  reviewToOrder: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/findrevieworder",
  },

  masterSKU: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/masterskumapping",
  },
  getAllFilters: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PAYMENT_URL,
    endpoint: "/all_filters",
  },
  getProfitability: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PAYMENT_URL,
    endpoint: "/post_profitability",
  },
  profitabilitysummary: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PAYMENT_URL,
    endpoint: "/profitabilitysummary",
  },
  profitabilityinventory: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PAYMENT_URL,
    endpoint: "/profitabilityinventory",
  },

  getPayments: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PAYMENT_URL,
    endpoint: "/payments",
  },
  PaymentSummary: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/paymentsummary",
  },
  bankStatement: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/bankstatement",
  },

  getBuybox: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/buyboxv2",
  },
  campaignInventory: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/campaign_inventory",
  },
  createCampaign: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/create-campaign",
  },
  getCampaigns: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/list-email-campaigns",
  },
  getCampaignDetails: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/view-email-campaigns",
  },
  deleteCampaign: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/delete-email-campaigns",
  },
  channelAdd: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    endpoint: "/addchannel",
  },
  getInventory_new: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    // endpoint: "/get_inventory_ledger",
    endpoint: "/all_inventory",
  },
  inventorySummary: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    // endpoint: "/get_inventory_summary",
    endpoint: "/inventory_summary",
  },
  myntraReconcilation: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    endpoint: "/myntra_recon_trigger",
  },
  admin_update_module_details: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_ADMIN_URL,
    endpoint: "/admin-update-module-details",
  },
  manualReconcilitionUpload: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PAYMENT_URL,
    endpoint: "/manual_recon",
  },

  cashback: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PAYMENT_URL,
    endpoint: "/cashback ",
  },
  modular_inventory: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/modular_inventory",
  },
  shopifyupload: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/shopifyupload",
  },

  invoice_upload: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/invoice_upload",
  },
  otherExpenses: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/other_expenses",
  },
  pricingComplaints: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/pricingcomplaints",
  },
  // ---------file upload -velprajan---------------------------
  file_upload: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/file_upload",
  },
  // -----------------------------------------------------------------

  // ----------invoice--------------------------
  getInvoice: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/invoice",
  },
  getInvoicereconciliation: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/invoice_reconciliation",
  },

  // ---------------------------------------------------------------------
  uploadApiRequest: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/uploadapirequest",
  },

  //--------------------new Price Tracker---------------------------------------
  getPriceTracker: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/fs_pricing",
  },

  //---------------------------------------
  //--------------------outsttanding payments---------------------------------------
  get_outstanding_payments: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/outstanding_payments",
  },

  //---------------------------------------
  // -----------------------exception rules---------------------------
  create_exception_rules: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/exception_rules",
  },

  // ------list------------------
  exception_rules: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/get_exception_rules",
  },
  getCommonNodeApi: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/commonapi",
  },
  // --------------------Profit & Loss monthwise-----------------
  get_Profit_Loss_Monthwise: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/get_profitability_monthwise",
  },

  //---------------------------------------------------------------
  // --------------------Buybox Multiple Pincode-----------------
  get_buybox_multiple_pincode: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/multiple-pincode-buybox-tracking",
  },

  //---------------------------------------------------------------
  // --------------------Buybox & Pricing profit-----------------
  get_profitability_buybox_pricing: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/get_profitability_buybox_pricing",
  },

  //---------------------------------------------------------------
  // --------------------Admin panel reconcilation trigger-----------------
  reconciliation_trigger: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/get_reconciliation_trigger",
  },

  //---------------------------------------------------------------
  // --------------------Fs Claims-----------------
  fsClaims: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/fs_claims",
  },

  //-------------------------------------------------------

  //---------------------------------------------------------------
  // --------------------Pradeep upload-----------------
  get_analytics_settings: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_ORDER_URL,
    endpoint: "/get_analytics_settings_input",
  },

  get_dbd_profitability: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_ORDER_URL,
    endpoint: "/get_day_by_day_profitability",
  },

  breakupfees: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PAYMENT_URL,
    endpoint: "/breakup_fees ",
  },
  AmazonVendorBankRecon: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PAYMENT_URL,
    endpoint: "/amazon_vendor_bank_reconciliation ",
  },
  FlipkartPcaAdsUpload: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    endpoint: "/get_flipkart_pca_ads_upload",
  },
  // Settled and Fee Recon pages
  ConfigureReconciliationRules: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PAYMENT_URL,
    endpoint: "/configure_reconciliation_rules",
  },
  // Settled and Fee config pages
  GetReconciliation: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    endpoint: "/get_all_channel_reconciliation",
  },
  CashbackDiscrepancy: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PAYMENT_URL,
    endpoint: "/get-cashback-discrepancy",
  },
  MeeshoFileUpload: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    endpoint: "/meesho_file_upload",
  },
  // multiple architecture - get channels / add channel / update channel ----------------
  get_channels_v2: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_CHANNEL_URL,
    endpoint: "/get-channels-v2",
  },
  add_channel_v2: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_CHANNEL_URL,
    endpoint: "/add-channel-v2",
  },
  update_channel_v2: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_CHANNEL_URL,
    endpoint: "/update-channel-v2",
  },
  // ------------------------------------------------------------------------
  // --------------new returns tracker------------------------------------------
returns_tracker: {
  callType: "POST",
  isAuthenticated: true,
  baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
  endpoint: "/return",
},
// --------------Admin Analytics------------------------------------------
admin_client_analytics_post: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_ADMIN_URL,
    endpoint: "/admin-client-analytics-post",
  },

};
